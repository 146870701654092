import React from "react";

const Home = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 54 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.3774 48.2232H0.840125C0.420063 48.2232 0 47.8872 0 47.3831C0 46.879 0.33605 46.543 0.840125 46.543H36.3774C36.7975 46.543 37.2175 46.879 37.2175 47.3831C37.2175 47.8872 36.7975 48.2232 36.3774 48.2232Z"
        fill="#252525"
      />
      <path
        d="M36.3777 48.2232H5.96513C5.54506 48.2232 5.125 47.8871 5.125 47.3831V25.9599C5.125 25.5398 5.46105 25.1198 5.96513 25.1198C6.4692 25.1198 6.80525 25.4558 6.80525 25.9599V46.5429H36.3777C36.7977 46.5429 37.2178 46.879 37.2178 47.3831C37.2178 47.8871 36.7977 48.2232 36.3777 48.2232Z"
        fill="#252525"
      />
      <path
        d="M3.78056 27.6401C2.77241 27.6401 1.84828 27.3041 1.09216 26.548C0.420063 25.8759 0 24.8677 0 23.8596C0 22.9354 0.420063 22.0113 1.00815 21.2552L20.247 2.0163C22.9354 -0.6721 27.2201 -0.6721 29.9085 2.0163L38.1417 10.2495C38.4777 10.5856 38.4777 11.0897 38.1417 11.4257C37.8056 11.7618 37.3016 11.7618 36.9655 11.4257L28.7323 3.19248C26.716 1.17618 23.4395 1.17618 21.4232 3.19248L2.26834 22.3473C1.93229 22.7674 1.68025 23.3555 1.68025 23.8596C1.68025 24.2796 1.76426 24.8677 2.26834 25.3718C3.10846 26.2119 4.53668 26.2119 5.3768 25.3718L24.5317 6.21693C24.8677 5.88088 25.3718 5.88088 25.7078 6.21693L32.6809 13.19C33.0169 13.526 33.0169 14.0301 32.6809 14.3661C32.3448 14.7022 31.8407 14.7022 31.5047 14.3661L25.1197 7.98119L6.55298 26.548C5.79686 27.3041 4.78871 27.6401 3.78056 27.6401Z"
        fill="#252525"
      />
      <path
        d="M27.6371 48.2232H15.7073C15.2873 48.2232 14.8672 47.8871 14.8672 47.383V32.0928C14.8672 30.7486 15.9594 29.5724 17.3876 29.5724H26.0409C27.3851 29.5724 28.4772 30.7486 28.4772 32.0928V47.383C28.4772 47.8031 28.0572 48.2232 27.6371 48.2232ZM16.5474 46.5429H26.797V32.0928C26.797 31.5887 26.3769 31.2526 26.0409 31.2526H17.3876C16.8835 31.2526 16.5474 31.6727 16.5474 32.0928V46.5429Z"
        fill="#252525"
      />
      <path
        d="M41.5861 48.1392C40.494 48.1392 39.4858 47.5511 38.8977 46.6269C35.9573 41.7542 29.3203 29.9925 29.3203 24.9517C29.3203 18.2307 34.7811 12.7699 41.5021 12.7699C48.2231 12.7699 53.768 18.2307 53.768 24.9517C53.768 28.4802 50.5755 35.7053 44.3586 46.6269C43.6865 47.5511 42.6783 48.1392 41.5861 48.1392ZM41.5021 14.4501C35.7053 14.4501 31.0006 19.1548 31.0006 24.9517C31.0006 29.6564 38.1416 42.0903 40.326 45.7868C40.578 46.2069 41.0821 46.5429 41.6702 46.5429C42.2582 46.5429 42.6783 46.2909 43.0144 45.7868C50.4915 32.5969 52.0877 27.136 52.0877 24.9517C52.0877 19.1548 47.383 14.4501 41.5021 14.4501Z"
        fill="#252525"
      />
      <path
        d="M41.5866 32.7649C37.302 32.7649 33.7734 29.2364 33.7734 24.9517C33.7734 20.6671 37.302 17.1385 41.5866 17.1385C45.8712 17.1385 49.3998 20.6671 49.3998 24.9517C49.3998 29.2364 45.8712 32.7649 41.5866 32.7649ZM41.5866 18.8188C38.2261 18.8188 35.4537 21.5912 35.4537 24.9517C35.4537 28.3122 38.2261 31.0846 41.5866 31.0846C44.9471 31.0846 47.7195 28.3122 47.7195 24.9517C47.7195 21.5912 44.9471 18.8188 41.5866 18.8188Z"
        fill="#252525"
      />
      <path
        d="M18.2308 40.41C17.8107 40.41 17.3906 40.0739 17.3906 39.5699V38.4777C17.3906 38.0576 17.7267 37.6376 18.2308 37.6376C18.7348 37.6376 19.0709 37.9736 19.0709 38.4777V39.5699C19.0709 39.9899 18.6508 40.41 18.2308 40.41Z"
        fill="#252525"
      />
    </svg>
  );
};

export default Home;
