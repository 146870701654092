import React from "react";

const Clock = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0014 5.86958C18.4318 5.86958 18.784 5.5174 18.784 5.08697V4.69566C18.784 4.26523 18.4318 3.91306 18.0014 3.91306C17.5709 3.91306 17.2188 4.26523 17.2188 4.69566V5.08697C17.2188 5.5174 17.5709 5.86958 18.0014 5.86958Z"
        fill="#467888"
      />
      <path
        d="M18.0014 30.1304C17.5709 30.1304 17.2188 30.4826 17.2188 30.913V31.3043C17.2188 31.7348 17.5709 32.087 18.0014 32.087C18.4318 32.087 18.784 31.7348 18.784 31.3043V30.913C18.784 30.4826 18.4318 30.1304 18.0014 30.1304Z"
        fill="#467888"
      />
      <path
        d="M5.08798 17.2174H4.69667C4.26624 17.2174 3.91406 17.5696 3.91406 18C3.91406 18.4304 4.26624 18.7826 4.69667 18.7826H5.08798C5.51841 18.7826 5.87058 18.4304 5.87058 18C5.87058 17.5696 5.51841 17.2174 5.08798 17.2174Z"
        fill="#467888"
      />
      <path
        d="M31.3067 17.2174H30.9154C30.485 17.2174 30.1328 17.5696 30.1328 18C30.1328 18.4304 30.485 18.7826 30.9154 18.7826H31.3067C31.7372 18.7826 32.0893 18.4304 32.0893 18C32.0893 17.5696 31.7372 17.2174 31.3067 17.2174Z"
        fill="#467888"
      />
      <path
        d="M9.15856 8.06089C8.84552 7.74785 8.37595 7.74785 8.06291 8.06089C7.74986 8.37394 7.74986 8.8435 8.06291 9.15655L8.33682 9.43046C8.49334 9.58698 8.68899 9.66524 8.88465 9.66524C9.0803 9.66524 9.27595 9.58698 9.43247 9.43046C9.74552 9.11742 9.74552 8.64785 9.43247 8.33481L9.15856 8.06089Z"
        fill="#467888"
      />
      <path
        d="M8.33682 26.5696L8.06291 26.8435C7.74986 27.1565 7.74986 27.6261 8.06291 27.9391C8.21943 28.0956 8.41508 28.1739 8.61073 28.1739C8.80639 28.1739 9.00204 28.0956 9.15856 27.9391L9.43247 27.6652C9.74552 27.3522 9.74552 26.8826 9.43247 26.5696C9.11943 26.2565 8.64986 26.2565 8.33682 26.5696Z"
        fill="#467888"
      />
      <path
        d="M26.8446 8.06089L26.5707 8.33481C26.2577 8.64785 26.2577 9.11742 26.5707 9.43046C26.7272 9.58698 26.9229 9.66524 27.1185 9.66524C27.3142 9.66524 27.5098 9.58698 27.6664 9.43046L27.9403 9.15655C28.2533 8.8435 28.2533 8.37394 27.9403 8.06089C27.6272 7.74785 27.1577 7.74785 26.8446 8.06089Z"
        fill="#467888"
      />
      <path
        d="M18.784 17.687V10.5652C18.784 10.1348 18.4318 9.78262 18.0014 9.78262C17.5709 9.78262 17.2188 10.1348 17.2188 10.5652V18C17.2188 18.1957 17.297 18.3913 17.4535 18.5478L26.0231 27.1174C26.1796 27.2739 26.3753 27.3522 26.5709 27.3522C26.7666 27.3522 26.9622 27.2739 27.1188 27.1174C27.4318 26.8044 27.4318 26.3348 27.1188 26.0218L18.784 17.687Z"
        fill="#467888"
      />
      <path
        d="M18 0C8.06087 0 0 8.06087 0 18C0 27.9391 8.06087 36 18 36C27.9391 36 36 27.9391 36 18C36 8.06087 27.9391 0 18 0ZM18 34.4348C8.92174 34.4348 1.56522 27.0783 1.56522 18C1.56522 8.92174 8.92174 1.56522 18 1.56522C27.0783 1.56522 34.4348 8.92174 34.4348 18C34.4348 27.0783 27.0783 34.4348 18 34.4348Z"
        fill="#467888"
      />
    </svg>
  );
};

export default Clock;
