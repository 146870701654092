import React from 'react'

const Profile = () => {
  return (
    <svg
        width="28"
        height="28"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.9864 30.0938C29.6633 26.0775 25.9395 23.3353 21.6252 22.3467C23.6753 21.5068 25.3701 19.981 26.4202 18.0302C27.4702 16.0793 27.8102 13.8244 27.3822 11.6506C26.9542 9.47685 25.7846 7.51917 24.0734 6.11202C22.3621 4.70487 20.2154 3.93558 17.9999 3.93558C15.7844 3.93558 13.6376 4.70487 11.9264 6.11202C10.2151 7.51917 9.04555 9.47685 8.61752 11.6506C8.18949 13.8244 8.52953 16.0793 9.57956 18.0302C10.6296 19.981 12.3244 21.5068 14.3745 22.3467C10.0658 23.3311 6.33642 26.0775 4.0133 30.0938C3.94605 30.2223 3.93107 30.3719 3.97149 30.5112C4.01192 30.6505 4.10461 30.7689 4.23022 30.8415C4.35583 30.9141 4.50463 30.9354 4.64554 30.9008C4.78646 30.8663 4.90859 30.7787 4.98642 30.6563C7.73423 25.9017 12.6027 23.0625 17.9999 23.0625C23.397 23.0625 28.2655 25.9017 31.0133 30.6563C31.0626 30.7417 31.1335 30.8126 31.2189 30.862C31.3043 30.9114 31.4012 30.9374 31.4999 30.9375C31.5987 30.9378 31.6958 30.9116 31.7811 30.8616C31.9101 30.7869 32.0041 30.6641 32.0426 30.5201C32.0811 30.3762 32.0609 30.2228 31.9864 30.0938ZM9.56236 13.5C9.56236 11.8312 10.0572 10.1999 10.9843 8.81238C11.9115 7.42484 13.2292 6.34338 14.771 5.70477C16.3127 5.06616 18.0092 4.89906 19.6459 5.22463C21.2826 5.55019 22.7861 6.35378 23.9661 7.53379C25.1461 8.7138 25.9497 10.2172 26.2752 11.8539C26.6008 13.4906 26.4337 15.1871 25.7951 16.7289C25.1565 18.2706 24.075 19.5884 22.6875 20.5155C21.2999 21.4427 19.6686 21.9375 17.9999 21.9375C15.7629 21.9349 13.6183 21.0451 12.0365 19.4633C10.4547 17.8816 9.56496 15.737 9.56236 13.5Z"
          fill="#467888"
        />
      </svg>
  )
}

export default Profile