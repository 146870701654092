import React from "react";

const Warning = () => {
  return (
    <svg
    width="14"
    height="14"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 26C5.81177 26 0 20.1882 0 13C0 5.81177 5.81177 0 13 0C20.1882 0 26 5.81177 26 13C26 20.1882 20.1882 26 13 26ZM13 1.52941C6.65294 1.52941 1.52941 6.65294 1.52941 13C1.52941 19.3471 6.65294 24.4706 13 24.4706C19.3471 24.4706 24.4706 19.3471 24.4706 13C24.4706 6.65294 19.3471 1.52941 13 1.52941Z"
      fill="#467888"
    />
    <path
      d="M12.2344 18.353H13.7638V19.8824H12.2344V18.353ZM13.4579 16.8236H12.5403L12.2344 10.7059V6.11768H13.7638V10.7059L13.4579 16.8236Z"
      fill="#467888"
    />
  </svg>
  );
};

export default Warning;
